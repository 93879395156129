<template>
   <div class="p-10">
      <section>
         <div class="text-base my-5">
            <div class="">
               <ul class="nav nav-tabs nav-justified flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0" id="tabs-tabJustify" role="tablist">
                  <li class="nav-item flex-grow text-center" role="presentation">
                     <a href="#allTransactions" class="nav-link w-1/2 mx-auto whitespace-nowrap font-semibold text-sm leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent
                        px-6 py-4 hover:border-transparent hover:text-indigo-700 focus:border-transparent active" id="tabs-home-tabJustify" data-bs-toggle="pill" data-bs-target="#allTransactions" role="tab"
                        aria-controls="allTransactions" aria-selected="true">All Transactions</a>
                  </li>
                  <li class="nav-item flex-grow text-center" role="presentation">
                     <a href="#allDeposits" class="nav-link w-1/2 mx-auto whitespace-nowrap font-semibold text-sm leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6
                        py-4 hover:border-transparent hover:text-indigo-700 focus:border-transparent" id="tabs-profile-tabJustify" data-bs-toggle="pill" data-bs-target="#allDeposits" role="tab"
                        aria-controls="allDeposits" aria-selected="false">All Deposits</a>
                  </li>
                  <li class="nav-item flex-grow text-center" role="presentation">
                     <a href="#allWithdrawals" class="nav-link w-1/2 mx-auto whitespace-nowrap font-semibold text-sm leading-tight uppercase border-x-0 border-t-0 border-b-2 
                        border-transparent px-6 py-4 hover:border-transparent hover:text-indigo-700
                        focus:border-transparent" id="tabs-messages-tabJustify" data-bs-toggle="pill" data-bs-target="#allWithdrawals" role="tab"
                        aria-controls="allWithdrawals" aria-selected="false">All Withdrawals</a>
                  </li>
               </ul>
            </div>
            <div class="absolute w-full relative">
               <div class="bg-white rounded-md shadow mt-2">
                  <div class="tab-content" id="tabs-tabContentJustify">
                     <div class="tab-pane fade show active" id="allTransactions" role="tabpanel" aria-labelledby="tabs-home-tabJustify">
                        <template v-if="isLoading">
                           <div class="p-52">
                              <svg class="animate-spin h-8 w-8 mx-auto text-indigo-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                 <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                 <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                              </svg>
                           </div>
                        </template>
                        <template v-else>
                           <template v-if="transactionData.length == 0">
                              <section class="grid place-content-center py-24">
                                 <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="w-20 h-20 mx-auto text-gray-200">
                                       <path fill="currentColor" d="M20.64026,15.77179A3.46849,3.46849,0,0,0,21.5,13.5a3.5,3.5,0,0,0-7,0,3.46849,3.46849,0,0,0,.85974,2.27179A4.98821,4.98821,0,0,0,13,20a1,1,0,0,0,2,0,3,3,0,0,1,6,0,1,1,0,0,0,2,0A4.98821,4.98821,0,0,0,20.64026,15.77179ZM18,15a1.5,1.5,0,1,1,1.5-1.5A1.50164,1.50164,0,0,1,18,15ZM6.79327,7.70734,8.793,9.707A.99989.99989,0,0,0,10.207,8.293L9.91406,8h4.17188l-.293.293A.99989.99989,0,1,0,15.207,9.707l1.9997-1.99969a1.00354,1.00354,0,0,0,0-1.41468L15.207,4.293A.99989.99989,0,0,0,13.793,5.707l.293.293H9.91406l.293-.293A.99989.99989,0,0,0,8.793,4.293L6.79327,6.29266a1.00354,1.00354,0,0,0,0,1.41468Zm1.847,8.06445A3.46849,3.46849,0,0,0,9.5,13.5a3.5,3.5,0,0,0-7,0,3.46849,3.46849,0,0,0,.85974,2.27179A4.98821,4.98821,0,0,0,1,20a1,1,0,0,0,2,0,3,3,0,0,1,6,0,1,1,0,0,0,2,0A4.98821,4.98821,0,0,0,8.64026,15.77179ZM6,15a1.5,1.5,0,1,1,1.5-1.5A1.50164,1.50164,0,0,1,6,15Z"/></svg>
                                    <p class="text-lg text-gray-400 pt-5">There's nothing here yet. Click below to start a new transaction.</p>
                                    <div class="text-center my-5">
                                       <router-link :to="{ name: 'CreateTransaction' }" class="bg-indigo-900 text-white rounded py-2.5 border-gray-900 border hover:bg-indigo-800 transition-colors px-5">Start New Transaction</router-link>
                                    </div>
                                 </div>
                              </section>
                           </template>
                           <template v-else>
                              <form action="" class="flex justify-end p-5">
                                 <div class="mr-5 relative w-2/5">
                                    <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                                       <svg class="w-5 h-5 text-gray-400" viewBox="0 0 24 24" fill="none">
                                          <path d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                       </svg>
                                    </span>
                                    <input type="text" class="form-input w-full py-2 pl-10 pr-4 bg-gray-50 transition duration-150 ease-in-out border border-gray-300 rounded-md placeholder:text-indigo-900 focus:border-blue-500 focus:outline-none" placeholder="Search transaction by ID">
                                 </div> 
                                 <div class="mr-5 w-40">
                                    <button type="button" class="form-input flex w-full py-2 bg-gray-100 transition duration-150 ease-in-out border border-gray-300 rounded-md placeholder-body focus:border-blue-500 focus:outline-none">
                                       <span class="flex items-center px-3">
                                          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                                          </svg>
                                       </span>
                                       <span class="font-medium text-gray-600">Filter</span>
                                    </button>
                                 </div>
                                 <router-link :to="{ name: 'CreateTransaction' }" class="bg-indigo-900 text-white rounded py-2 border-gray-900 border hover:bg-indigo-800 transition-colors px-5">Start New Transaction</router-link>
                              </form>
                              <div class="pb-5">
                                 <div class="overflow-x-auto w-full">
                                    <table class="mx-auto max-w-4xl w-full whitespace-nowrap overflow-hidden">
                                       <thead class="border-b bg-gray-100 uppercase text-sm text-indigo-900">
                                          <tr>
                                             <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                                Transaction ID
                                             </th>
                                             <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                                Title
                                             </th>
                                             <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                                Type
                                             </th>
                                             <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                                Currency
                                             </th>
                                             <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                                Price
                                             </th>
                                             <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                                Role
                                             </th>
                                             <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                                Status
                                             </th>
                                             <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                                Date Created
                                             </th>
                                             <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                                Action
                                             </th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr class="border-b" v-for="(t, index) in transactionData" :key="index">
                                             <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">{{ t.invoice_id }}</td>
                                             <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">{{ t.service_name | capitalize }}</td>
                                             <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">{{ t.category | capitalize }}</td>
                                             <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap uppercase">{{ t.currency }}</td>
                                             <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">
                                                <span v-if="t.currency == 'usd'">&#36;</span>
                                                <span v-if="t.currency == 'ngn'">&#8358;</span>
                                                <span v-if="t.currency == 'jpy'">&#165;</span>
                                                <span v-if="t.currency == 'btc'" class="pr-1">BTC</span>{{ t.price | formatPrice }}</td>
                                             <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">
                                                <span v-if="t.creator_email == user.email">{{ t.creator_role | capitalize }}</span>
                                                <span v-else>
                                                   <span v-if="t.creator_role == 'buyer'">Seller</span>
                                                   <span v-else>Buyer</span>
                                                </span>
                                             </td>
                                             <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">
                                                <span class="px-3 py-1.5 bg-indigo-200 text-xs uppercase rounded-full cursor-pointer" v-if="t.status == 'pending'">{{ t.status | capitalize }}</span>
                                                <span class="px-3 py-1.5 bg-green-200 text-xs uppercase rounded-full cursor-pointer" v-else-if="t.status == 'completed' || t.status == 'resolved' || t.status == 'accepted'" >{{ t.status | capitalize }}</span>
                                                <span class="px-3 py-1.5 bg-red-200 text-xs uppercase rounded-full cursor-pointer" v-else-if="t.status == 'rejected' || t.status == 'cancelled'">{{ t.status | capitalize }}</span>
                                                <span class="px-3 py-1.5 bg-yellow-200 text-xs uppercase rounded-full cursor-pointer" v-else-if="t.status == 'disputed'">{{ t.status | capitalize }}</span>
                                                <span class="px-3 py-1.5 bg-blue-500 text-xs text-white uppercase rounded-full cursor-pointer" v-else-if="t.status == 'paid'">{{ t.status | capitalize }}</span>
                                                <span class="px-3 py-1.5 bg-gray-300 text-xs uppercase rounded-full cursor-pointer" v-else>{{ t.status | capitalize }}</span>
                                             </td>
                                             <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">{{ t.created_at | formatDate }}</td>
                                             <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap flex justify-start space-x-3">
                                                <router-link :to="{ name: 'SelectedTransaction', params: { id: t.invoice_id }}" class="p-2 rounded-md hover:bg-gray-100 bg-gray-50 transition cursor-pointer" title="View transaction">
                                                   <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                   </svg>
                                                </router-link>
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                           </template>
                        </template>
                     </div>
                     <div class="tab-pane fade" id="allDeposits" role="tabpanel" aria-labelledby="tabs-profile-tabJustify">
                        <template v-if="isLoading">
                           <div class="p-52">
                              <svg class="animate-spin h-10 w-10 mx-auto text-indigo-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                 <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                 <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                              </svg>
                           </div>
                        </template>
                        <template v-else>
                           <template v-if="depositData.length == 0">
                              <section class="grid place-content-center py-24">
                                 <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-20 h-20 mx-auto text-gray-200"><path fill="currentColor" d="M6,11a1,1,0,1,0,1,1A1,1,0,0,0,6,11Zm12,0a1,1,0,1,0,1,1A1,1,0,0,0,18,11Zm2-6H4A3,3,0,0,0,1,8v8a3,3,0,0,0,3,3H20a3,3,0,0,0,3-3V8A3,3,0,0,0,20,5Zm1,11a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V8A1,1,0,0,1,4,7H20a1,1,0,0,1,1,1ZM12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13Z"/></svg>
                                    <p class="text-lg text-gray-400 py-5">No deposits has been made yet.</p>
                                 </div>
                              </section>
                           </template>
                           <template v-else>
                              <form action="" class="flex justify-end p-5">
                                 <div class="mr-5 relative w-2/5">
                                    <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                                       <svg class="w-5 h-5 text-gray-400" viewBox="0 0 24 24" fill="none">
                                          <path d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                       </svg>
                                    </span>
                                    <input type="text" class="form-input w-full py-2 pl-10 pr-4 bg-gray-50 transition duration-150 ease-in-out border border-gray-300 rounded-md placeholder:text-indigo-900 focus:border-blue-500 focus:outline-none" placeholder="Search transaction by ID">
                                 </div> 
                                 <div class="mr-5 w-40">
                                    <button type="button" class="form-input flex w-full py-2 bg-gray-100 transition duration-150 ease-in-out border border-gray-300 rounded-md placeholder-body focus:border-blue-500 focus:outline-none">
                                       <span class="flex items-center px-3">
                                          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                                          </svg>
                                       </span>
                                       <span class="font-medium text-gray-600">Filter</span>
                                    </button>
                                 </div>
                              </form>
                              <div class="pb-5">
                                 <div class="overflow-x-auto w-full">
                                    <table class="mx-auto max-w-4xl w-full whitespace-nowrap overflow-hidden">
                                       <thead class="border-b bg-gray-100 uppercase text-sm text-indigo-900">
                                          <tr>
                                             <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                                Transaction ID
                                             </th>
                                             <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                                Title
                                             </th>
                                             <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                                Type
                                             </th>
                                             <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                                Currency
                                             </th>
                                             <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                                Price
                                             </th>
                                             <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                                Role
                                             </th>
                                             <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                                Date Created
                                             </th>
                                             <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                                Status
                                             </th>
                                             <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                                Action
                                             </th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr class="border-b" v-for="(t, index) in transactionData.data" :key="index">
                                             <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">{{ t.invoice_id }}</td>
                                             <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">{{ t.service_name | capitalize }}</td>
                                             <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">{{ t.category | capitalize }}</td>
                                             <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap uppercase">{{ t.currency }}</td>
                                             <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">
                                                <span v-if="t.currency == 'usd'">&#36;</span>
                                                <span v-if="t.currency == 'ngn'">&#8358;</span>
                                                <span v-if="t.currency == 'jpy'">&#165;</span>
                                                <span v-if="t.currency == 'btc'" class="pr-1">BTC</span>{{ t.price | formatPrice }}</td>
                                             <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">
                                                <span v-if="t.creator_role == 'buyer'">{{ 'seller' | capitalize }}</span><span v-else>{{ 'buyer' | capitalize }}</span></td>
                                             <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">{{ t.created_at | formatDate }}</td>
                                             <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">
                                                <span class="px-3 py-1.5 bg-indigo-200 text-xs uppercase rounded-full cursor-pointer" v-if="t.status == 'pending'">{{ t.status | capitalize }}</span>
                                                <span class="px-3 py-1.5 bg-green-200 text-xs uppercase rounded-full cursor-pointer" v-else-if="t.status == 'completed' || t.status == 'resolved'">{{ t.status | capitalize }}</span>
                                                <span class="px-3 py-1.5 bg-red-200 text-xs uppercase rounded-full cursor-pointer" v-else-if="t.status == 'rejected' || t.status == 'cancelled'">{{ t.status | capitalize }}</span>
                                                <span class="px-3 py-1.5 bg-yellow-200 text-xs uppercase rounded-full cursor-pointer" v-else-if="t.status == 'disputed'">{{ t.status | capitalize }}</span>
                                                <span class="px-3 py-1.5 bg-gray-300 text-xs uppercase rounded-full cursor-pointer" v-else>{{ t.status | capitalize }}</span>
                                             </td>
                                             <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap flex justify-start space-x-3">
                                                <div class="p-2 rounded-md hover:bg-gray-100 bg-gray-50 transition cursor-pointer" title="View transaction">
                                                   <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                   </svg>
                                                </div>
                                                <div class="p-2 rounded-md hover:bg-gray-100 bg-gray-50 transition cursor-pointer" title="Delete transaction" v-if="user.email == t.creator_email">
                                                   <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                   </svg>
                                                </div>
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                           </template>
                        </template>
                     </div>
                     <div class="tab-pane fade" id="allWithdrawals" role="tabpanel" aria-labelledby="tabs-profile-tabJustify">
                        <template v-if="isLoading">
                           <div class="p-52">
                              <svg class="animate-spin h-10 w-10 mx-auto text-indigo-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                 <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                 <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                              </svg>
                           </div>
                        </template>
                        <template v-else>
                           <template v-if="withdrawalData.length == 0">
                              <section class="grid place-content-center py-24">
                                 <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-20 h-20 mx-auto text-gray-200"><path fill="currentColor" d="M12,12a3,3,0,1,0,3,3A3,3,0,0,0,12,12Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,16Zm-.71-6.29a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21L15,7.46A1,1,0,1,0,13.54,6L13,6.59V3a1,1,0,0,0-2,0V6.59L10.46,6A1,1,0,0,0,9,7.46ZM19,15a1,1,0,1,0-1,1A1,1,0,0,0,19,15Zm1-7H17a1,1,0,0,0,0,2h3a1,1,0,0,1,1,1v8a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V11a1,1,0,0,1,1-1H7A1,1,0,0,0,7,8H4a3,3,0,0,0-3,3v8a3,3,0,0,0,3,3H20a3,3,0,0,0,3-3V11A3,3,0,0,0,20,8ZM5,15a1,1,0,1,0,1-1A1,1,0,0,0,5,15Z"/></svg>
                                    <p class="text-lg text-gray-400 py-5">No withdrawals has been made yet.</p>
                                 </div>
                              </section>
                           </template>
                           <template v-else>
                              <form action="" class="flex justify-end p-5">
                                 <div class="mr-5 relative w-2/5">
                                    <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                                       <svg class="w-5 h-5 text-gray-400" viewBox="0 0 24 24" fill="none">
                                          <path d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                       </svg>
                                    </span>
                                    <input type="text" class="form-input w-full py-2 pl-10 pr-4 bg-gray-50 transition duration-150 ease-in-out border border-gray-300 rounded-md placeholder:text-indigo-900 focus:border-blue-500 focus:outline-none" placeholder="Search transaction by ID">
                                 </div> 
                                 <div class="mr-5 w-40">
                                    <button type="button" class="form-input flex w-full py-2 bg-gray-100 transition duration-150 ease-in-out border border-gray-300 rounded-md placeholder-body focus:border-blue-500 focus:outline-none">
                                       <span class="flex items-center px-3">
                                          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                                          </svg>
                                       </span>
                                       <span class="font-medium text-gray-600">Filter</span>
                                    </button>
                                 </div>
                              </form>
                              <div class="pb-5">
                                 <div class="overflow-x-auto w-full">
                                    <table class="mx-auto max-w-4xl w-full whitespace-nowrap overflow-hidden">
                                       <thead class="border-b bg-gray-100 uppercase text-sm text-indigo-900">
                                          <tr>
                                             <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                                Transaction ID
                                             </th>
                                             <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                                Title
                                             </th>
                                             <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                                Type
                                             </th>
                                             <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                                Currency
                                             </th>
                                             <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                                Price
                                             </th>
                                             <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                                Role
                                             </th>
                                             <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                                Date Created
                                             </th>
                                             <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                                Status
                                             </th>
                                             <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                                Action
                                             </th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr class="border-b" v-for="(t, index) in transactionData.data" :key="index">
                                             <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">{{ t.invoice_id }}</td>
                                             <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">{{ t.service_name | capitalize }}</td>
                                             <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">{{ t.category | capitalize }}</td>
                                             <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap uppercase">{{ t.currency }}</td>
                                             <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">
                                                <span v-if="t.currency == 'usd'">&#36;</span>
                                                <span v-if="t.currency == 'ngn'">&#8358;</span>
                                                <span v-if="t.currency == 'jpy'">&#165;</span>
                                                <span v-if="t.currency == 'btc'" class="pr-1">BTC</span>{{ t.price | formatPrice }}</td>
                                             <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">
                                                <span v-if="t.creator_role == 'buyer'">{{ 'seller' | capitalize }}</span><span v-else>{{ 'buyer' | capitalize }}</span></td>
                                             <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">{{ t.created_at | formatDate }}</td>
                                             <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">
                                                <span class="px-3 py-1.5 bg-indigo-200 text-xs uppercase rounded-full cursor-pointer" v-if="t.status == 'pending'">{{ t.status | capitalize }}</span>
                                                <span class="px-3 py-1.5 bg-green-200 text-xs uppercase rounded-full cursor-pointer" v-else-if="t.status == 'completed' || t.status == 'resolved'">{{ t.status | capitalize }}</span>
                                                <span class="px-3 py-1.5 bg-red-200 text-xs uppercase rounded-full cursor-pointer" v-else-if="t.status == 'rejected' || t.status == 'cancelled'">{{ t.status | capitalize }}</span>
                                                <span class="px-3 py-1.5 bg-yellow-200 text-xs uppercase rounded-full cursor-pointer" v-else-if="t.status == 'disputed'">{{ t.status | capitalize }}</span>
                                                <span class="px-3 py-1.5 bg-gray-300 text-xs uppercase rounded-full cursor-pointer" v-else>{{ t.status | capitalize }}</span>
                                             </td>
                                             <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap flex justify-start space-x-3">
                                                <div class="p-2 rounded-md hover:bg-gray-100 bg-gray-50 transition cursor-pointer" title="View transaction">
                                                   <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                   </svg>
                                                </div>
                                                <div class="p-2 rounded-md hover:bg-gray-100 bg-gray-50 transition cursor-pointer" title="Delete transaction" v-if="user.email == t.creator_email">
                                                   <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                   </svg>
                                                </div>
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                           </template>
                        </template>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id="transactionModal" tabindex="-1" aria-labelledby="transactionModalTitle" aria-modal="true" role="dialog">
         <div class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
            <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
               <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 px-8 border-b border-gray-200 rounded-t-md">
               <h5 class="text-xl font-medium leading-normal text-gray-800">
                  Transaction Detials | {{ selectedToView.invoice_id }}
               </h5>
               </div>
               <div class="modal-body relative p-4 px-8">
                  <div class="flex justify-between pb-3">
                     <div class="">
                        Transaction Title:
                     </div>
                     <div>
                        {{ selectedToView.service_name | capitalize }}
                     </div>
                  </div>
                  <div class="flex justify-between pb-3">
                     <div class="">
                        Transaction ID:
                     </div>
                     <div>
                        {{ selectedToView.invoice_id }}
                     </div>
                  </div>
                  <div class="flex justify-between pb-3">
                     <div class="">
                        Creator's Name:
                     </div>
                     <div>
                        {{ selectedToView.creator_name | capitalize }}
                     </div>
                  </div>
                  <div class="flex justify-between pb-3">
                     <div class="">
                        Creator's Email:
                     </div>
                     <div>
                        {{ selectedToView.creator_email }}
                     </div>
                  </div>
                  <div class="flex justify-between pb-3">
                     <div class="">
                        Creator's Phone:
                     </div>
                     <div>
                        {{ selectedToView.creator_phone }}
                     </div>
                  </div>
                  <div class="flex justify-between pb-3">
                     <div class="">
                        Invitee's Name:
                     </div>
                     <div>
                        {{ selectedToView.invitee_name | capitalize }}
                     </div>
                  </div>
                  <div class="flex justify-between pb-3">
                     <div class="">
                        Invitee's Email:
                     </div>
                     <div>
                        {{ selectedToView.invitee_email }}
                     </div>
                  </div>
                  <div class="flex justify-between pb-3">
                     <div class="">
                        Invitee's Phone:
                     </div>
                     <div>
                        {{ selectedToView.invitee_phone }}
                     </div>
                  </div>
                  <div class="flex justify-between pb-3">
                     <div class="">
                        My Role:
                     </div>
                     <div>
                        <span v-if="selectedToView.creator_email == user.email">{{ selectedToView.creator_role | capitalize }}</span>
                        <span v-else>
                           <span v-if="selectedToView.creator_role == 'buyer'">Seller</span>
                           <span v-else>Buyer</span>
                        </span>
                     </div>
                  </div>
                  <div class="flex justify-between pb-3">
                     <div class="">
                        Transaction Category:
                     </div>
                     <div>
                        {{ selectedToView.category | capitalize }}
                     </div>
                  </div>
                  <div class="flex justify-between pb-3">
                     <div class="">
                        Amount:
                     </div>
                     <div>
                        <span v-if="selectedToView.currency == 'usd'">&#36;{{ selectedToView.price | formatPrice }}</span>
                        <span v-if="selectedToView.currency == 'ngn'">&#8358;{{ selectedToView.price | formatPrice }}</span>
                        <span v-if="selectedToView.currency == 'cny'">&#165;{{ selectedToView.price | formatPrice }}</span>
                        <span v-if="selectedToView.currency == 'usdt'">{{ selectedToView.price | formatPrice }} USDT</span> 
                        <span v-if="selectedToView.currency == 'busd'">{{ selectedToView.price | formatPrice }} BUSD</span>
                     </div>
                  </div>
                  <div class="pb-3">
                     <div class="">
                        Additional Description:
                     </div>
                     <div class="pt-2 text-sm">
                        {{ selectedToView.description | capitalize }}
                     </div>
                  </div>
                  <div class="flex justify-between pb-3">
                     <div class="">
                        Date Created:
                     </div>
                     <div>
                        {{ selectedToView.created_at | formatDate }}
                     </div>
                  </div>
                  <div class="flex justify-between pb-3">
                     <div class="">
                        Estimated Completion Date
                     </div>
                     <div>
                        {{ selectedToView.duration | formatDate }}
                     </div>
                  </div>
                  <div class="flex justify-between pb-3">
                     <div class="">
                        Status
                     </div>
                     <div>
                        {{ selectedToView.status | capitalize }}
                     </div>
                  </div>
               </div>
               <div class="modal-footer flex flex-shrink-0 space-x-2 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                  
                  <button type="button" v-if="selectedToView.invitee_email == user.email"
                     class="inline-block px-6 py-2.5 bg-green-500 border border-green-700 text-white font-medium text-xs leading-tight uppercase rounded hover:bg-green-600 hover:border-green-700 focus:ring-0 transition duration-150 ease-in-out">
                     Accept
                  </button>
                  <button type="button" @click="selectedToView = null" v-if="selectedToView.invitee_email == user.email"
                     class="inline-block px-6 py-2.5 bg-red-500 border border-red-700 text-white font-medium text-xs leading-tight uppercase rounded hover:bg-red-600 hover:border-red-700 focus:ring-0 transition duration-150 ease-in-out"
                     data-bs-dismiss="modal">
                     Decline
                  </button>
                  <button type="button" @click="selectedToView = null"
                     class="inline-block px-6 py-2.5 bg-gray-200 border border-gray-300 text-gray-800 font-medium text-xs leading-tight uppercase rounded hover:bg-gray-300 hover:border-gray-400 focus:ring-0 transition duration-150 ease-in-out"
                     data-bs-dismiss="modal">
                     Close
                  </button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";

export default {
   name: "Transactions",

   components: {
      
   },

   data() {
      return {
         isLoading: false,
         transactionData: [],
         depositData: [],
         withdrawalData: [],
         selectedToView: []
      }
   },

   computed: {
      ...mapGetters("auth", ["user"])
   },

   methods: {
      ...mapActions('transactions', ['get_transactions', 'get_payments']),

      getTransactionData() {
         let getRole = window.localStorage.getItem('transaction-role')
         getRole = JSON.parse(getRole)

         return getRole
      },

      async getTransactions(query) {
         this.isLoading = true
         const endpoint_params = {
            query: query
         }

         try{
            let res = await this.get_transactions(endpoint_params)
            this.transactionData = res.data.data
            this.isLoading = false
         } catch(error) {
            this.isLoading = false
         }
      },

      startNewTransaction() {
         if(this.getTransactionData() != null) {
            window.localStorage.removeItem('transaction-role')
         }
      },

      async getAllPayments(query) {
         this.isLoading = true
         const endpoint_params = {
            query: query
         }

         try {
            let res =  await this.get_payments(endpoint_params)
            if(query == 'deposit') {
               this.depositData = res.data
            } else if(query == 'withdrawal') {
               this.withdrawalData = res.data
            }
            this.isLoading = false
         } catch(error) {
            this.isLoading = false
         }
      }
   },

   mounted() {
      this.getTransactions('all')
      this.getAllPayments('deposit')
      this.getAllPayments('withdrawal')
   }
}
</script>